import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './config.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Events } from '@ionic/angular';


import { IOrg } from '../interfaces/org.interface';
import { IUserlogin } from '../interfaces/userlogin.interface';


@Injectable()
export class OrgService {

  private readonly configService: ConfigService = new ConfigService(); 

  constructor(
    private readonly httpClient: HttpClient,
    private readonly events: Events
  ) {

  }

  save(org: any): Observable<IOrg> {
    if (!!org._id){
      return this.httpClient.post<IOrg>('org/update', org);
    } else {
      return this.httpClient.post<IOrg>('org/create', org);
    }
  }

/* --We may use these later to allow logos to be uploaded....
  uploadimage(imageData: any): Observable<IOrg> {
    let headers = new HttpHeaders();
    //headers.append('Accept', 'application/json');
    //headers.append("Content-Type", "application/json");
    headers.append("Content-Type", 'multipart/form-data');

    return this.httpClient.post('property/image', imageData, { headers: headers });
  }

  getImage(imgId){
    if (!imgId){
      return 'assets/img/logo.png';
    }
    return this.configService.getSvcURL("property/image?imgId=" + imgId)
  }

  */
 
  delete(org: any): Observable<IOrg> {
    if (!org.id){
      return this.httpClient.get<IOrg>('org/delete?id='+ org);
    } else {
      return this.httpClient.get<IOrg>('org/delete?id='+ org.id);
    }
  }

  findAll(): Observable<IOrg[]> {
    return this.httpClient.get<IOrg[]>('org/');
  }

  findById(id: any): Observable<IOrg>{
    return this.httpClient.get<IOrg>('org/byid?id='+ id);
  }


  //UserLogin is safer, even though we remove the sensitive fields on the server. 
  findUsersforOrg(orgid: any): Observable<IUserlogin[]> {
    return this.httpClient.get<IUserlogin[]>('users/');
  }

  defaultOrg(){
    return {
      method: '',
      name: '',
      defaultroles: [],
      domian: '',
      supportemail: '',
      supportphone: '',
      supportname: '',
      inactive: false
      }
    };
}
