import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Events } from '@ionic/angular';

import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
	

	getConfig(){
				return environment;
	}

	getSvcURL(uri:string){
		var url = this.getConfig().server + uri
		/*if (localStorage.getItem('serviceurl')) {
			url = localStorage.getItem('serviceurl') + uri;
		}*/
		//wf is hosted on a non api URL
		return url.replace('/api/wf/', '/wf/');
	}
}

