import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyFilter',
  pure: false
})
export class PropertyFilterPipe implements PipeTransform {

  transform(items: any[], field: string, value: string): any {
    if (!items) {
      return [];
    }

    if (!value) {
      return items;
    }

    return items.filter(singleItem => this.searchForValue(singleItem, value));
  }

  private searchForValue (json: any, value: string): boolean {
    return JSON.stringify(json).toLowerCase().indexOf(value.toLowerCase()) != -1; // returns true if found
  }

}
