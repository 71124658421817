import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
//import { Geolocation } from '@ionic-native/geolocation';

@Injectable()
export class LocationService {

    public location: any;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigService) {

        //Temecula location for testing and demo.
        this.location = { "coords": { "latitude": 33.501109, "longitude": -117.073728 } }

        this.getCurrentPosition().then((resp) => {
            // resp.coords.latitude
            // resp.coords.longitude
        }).catch((error) => {
            console.log('Error getting location', error);
        });

        let watch = this.watchPosition();
        watch.subscribe((data) => {
            this.location = data;
            // data can be a set of coordinates, or an error (if an error occurred).
            // data.coords.latitude
            // data.coords.longitude
        });
    };

    getWatch() {
        return this.watchPosition();
    }

    getAddress(latitude: number, longitude: number): Observable<any> {
        return this.httpClient.get(this.latlngArg('geocode/reverse', latitude, longitude));
    };

    getGeoCode(address: string): Observable<any> {
        return this.httpClient.get('geocode/forward?address=' + address);
    };

    latlngArg(uri, lat, lng): string {
        return uri + "?lat=" + lat.toString() + "&lng=" + lng.toString();
    }

    getCurrentPosition = () => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }

    watchPosition = () => {
        return new Observable(subscriber => {
            navigator.geolocation.watchPosition(subscriber.next, subscriber.error);
         });
    }


}
