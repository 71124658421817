export const environment = {
  production: true,
  header: '',
  //mostly just used to lookup the server list from the discovery API
  server: 'https://' + window.location.hostname + '/api/',
  storagename: 'project_ecco_prod',
  lookupserver: false,
  jwt:
  {
    whitelistedDomains: ['ecco.test.kandsbennett.net', 'demo.ecco.pmcg.co', 'demo.valuclick.co', 'test.valuclick.co'],
    //blacklistedRoutes: ['ecco.test.kandsbennett.net/api/auth/local' , 'demo.acco.pmcg.co/api/auth/local', 'ecco.apps.pmcg.co/api/auth/local' ]
    //blacklistedRoutes: ['ecco.test.kandsbennett.net/api/auth/local/', 'demo.ecco.pmcg.co/api/auth/local/', 'sddemo.ecco.pmcg.co/api/auth/local/', window.location.hostname + '/api/auth/local/']
    //blacklistedRoutes: [window.location.hostname + '/api/auth/local/']
    blacklistedRoutes: [new RegExp('\/api\/auth\/local')]

  },
  squareApplicationId: 'sandbox-sq0idb--NlmpUPZOKnJ-6aZR8Mpeg'
};
