import { Component, ViewChild } from "@angular/core";
import { Platform, Events, ToastController, IonNav } from "@ionic/angular";

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HomePage } from "../home/home";
import { LoginPage } from "../login/login";
import { CreatePropertyPage } from "../createproperty/createproperty";
import {OrgAdminPage} from "../admin/org-admin";
import {ModifierAdminPage} from "../admin/modifier-admin";

import {AuthService} from "../../services/auth.service";
import {AppService} from "../../services/appservice.service";

import _ from 'lodash';

import {ProfilePage} from "../profile/profile";

export interface MenuItem {
    title: string;
    component: any;
    icon: string;
    roles: Array<string>;
}

@Component({
  selector: 'app-layout',
  templateUrl: 'applayout.component.html',
  styleUrls: ['./applayout.component.scss']
})

export class AppLayoutComponent {
  @ViewChild(IonNav) nav: IonNav;

  rootPage: any = LoginPage;

  appMenuItems: Array<MenuItem>;

  currentDate: number = Date.now();

  error: any;

  user: any = {
              firstname: "none",
              lastname: "none",
              roles: []
            };

  constructor(
    public platform: Platform,
    public statusBar: StatusBar,
    public splashScreen: SplashScreen,
    public auth: AuthService,
    public events: Events,
    public toastController: ToastController,
    public appService: AppService
  ) {
    this.initializeApp();
  }


  //Need to to this for login/out stuff:
  //https://forum.ionicframework.com/t/redirect-to-login-page-if-response-status-401/96629

  initializeApp() {
    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.

      //*** Control Splash Screen
       this.splashScreen.show();

      //*** Control Status Bar
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);

      //*** Control Keyboard: Disabled, due to compile error, move to android config later.
      //this.keyboard.disableScroll(true);
      this.splashScreen.hide();
    });

    this.events.subscribe('user:logout', () => {
      this.appService.clearSvcURL();
      this.nav.setRoot(LoginPage);
    });

    this.events.subscribe('user:profile', (user: any) => {
      this.user = user;
      //do this after login so we get the security applied.
      this.loadMenu(user);
    });

    ////events.publish('service.exception', err);
    this.events.subscribe('service.exception', (err: any) => {
      //this.error = err;
      if (_.isString(err.message)){
        return this.presentErrors(err.message);
      }
      return false;
    });

    this.auth.isAuthenticated().subscribe(
      (loggedin: Boolean) => {
          if (loggedin){
            this.nav.setRoot(HomePage);
          } else {
            this.auth.signOut()
          }
    });


  }

  loadMenu(user){
    this.appMenuItems = this.applyMenuSecurity(user, [
      {title: 'Property List', component: HomePage, icon: 'home', roles: []},
      {title: 'Add Property', component: CreatePropertyPage, icon: 'add-circle', roles:[]},
      {title: 'Organization Admin', component: OrgAdminPage, icon: 'person', roles:['admin']},
      {title: 'Feature VALUmodifier Admin', component: ModifierAdminPage, icon: 'person', roles:['admin']}
     ]);
  }

  async presentErrors(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 6000,
      position: 'top'
    });
    toast.present();
  }


  applyMenuSecurity(user, menuItems: Array<MenuItem>) {
    return menuItems.filter(function (menuItem){
      return  ((menuItem.roles.length < 1) || (_.intersection(menuItem.roles, user.roles).length > 0));
    });
  }

  editProfile() {
    this.nav.setRoot(ProfilePage);
  }

  openPage(page) {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    this.nav.setRoot(page.component);
  }

  openSimulatorPage () {
    
  }

  logout() {
      this.auth.signOut();
  }

}
