import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { NavController, ActionSheetController, ToastController, Platform, LoadingController } from '@ionic/angular';

// import { File, FileEntry } from '@ionic-native/file';
// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer';
// import { FilePath } from '@ionic-native/file-path';
// import { Camera } from '@ionic-native/camera';

import { ConfigService } from '../../services/config.service';
import { PropertyService } from "../../services/property.service";

declare var cordova: any;

@Component({
    selector: 'page-pictureload',
    templateUrl: 'pictureload.html'
})
export class PictureLaodPage implements OnInit {
    private readonly configService: ConfigService = new ConfigService();
    property: any = this.propertyService.defaultProperty();
    lastImage: any = null;
    image: any;
    loading: any;
    //public files: Set<File> = new Set();
    //@ViewChild('file') file: any;

    constructor(public navCtrl: NavController, private route: ActivatedRoute, private router: Router,
        public actionSheetCtrl: ActionSheetController, public toastCtrl: ToastController, public platform: Platform, 
        public loadingCtrl: LoadingController, public propertyService: PropertyService) {
    }
    ngOnInit(): void {
        this.property =  this.propertyService.defaultProperty();
        this.route.queryParams.subscribe(params => {
            if (this.router.getCurrentNavigation().extras.state) {                
                this.property = this.router.getCurrentNavigation().extras.state.sourceProperty;
                delete this.property['__v']; // removing version attribute to avoid save issues
            }
        });
    }

    /*  public presentActionSheet() {
        let actionSheet = this.actionSheetCtrl.create({
          header: 'Select Image Source',
          buttons: [
            {
              text: 'Load from Library',
              handler: () => {
                this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
              }
            },
            {
              text: 'Use Camera',
              handler: () => {
                this.takePicture(this.camera.PictureSourceType.CAMERA);
              }
            },
            {
              text: 'Cancel',
              role: 'cancel'
            }
          ]
        }).then(ctl => {
            ctl.present();
        });
        return actionSheet;
      } */

    public handleFiles(event: any) {
        //nothing yet.
        this.lastImage = "";
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            this.showLoading(() => {
                const reader = new FileReader();
                reader.onload = e => {
                    //this.lastImage = reader.result
    
                    let formData = new FormData();
                    formData.append('somekey', 'some value')
                    //formData.append('files', image);
                    formData.append('file', event.target.files[0]);
    
                    //this.propertyService.uploadimage({ id: '5c3555fc25622f558ce8db43' , image: reader.result }).subscribe(
                    this.propertyService.uploadimage(formData).subscribe(
                        (image: any) => {
                            //contains image.imgId
                            //this.lastImage = reader.result
                            this.saveImage(image);
                        });
                    //.catch((e: any) => {
                    //    console.log("App HTTP Log: " + e.toString());
                    //}); ;
                };
    
                reader.readAsDataURL(file);
            });

            //this.image = event.target.files[0];
            //this.uploadImage();
        }
    }

    private saveImage(image) {
        if (!this.property.images.includes(image.imgId)) {
            this.property.images.push(image.imgId);
            this.propertyService.save(this.property).subscribe(
                (property) => {
                    this.loading.dismiss();
                    this.presentToast('Image succesful uploaded.');
                    this.navCtrl.back();
                });
        } else {
            this.loading.dismiss();
            this.presentToast('Image succesful uploaded.');
            this.navCtrl.back();
        }
    }

    public ismobile() {
        return this.platform.is('cordova')
    }


    // Create a new name for the image
    private createFileName() {
        var d = new Date(),
            n = d.getTime(),
            newFileName = n + ".jpg";
        return newFileName;
    }

    // Copy the image to a local folder
    /*    private copyFileToLocalDir(namePath, currentName, newFileName) {
        this.file.copyFile(namePath, currentName, this.file.tempDirectory, newFileName).then(success => {
            this.image = newFileName;
        }, error => {
            this.presentToast('Error while storing file.');
        });
        }
        */
    private presentToast(text) {
        let toast = this.toastCtrl.create({
            message: text,
            duration: 3000,
            position: 'top'
        }).then(ctl => {
            ctl.present();
        });
        return toast;
    }

    // Always get the accurate path to your apps folder
    public pathForImage(img) {
        if (img === null) {
            return '';
        } else {
            // return (this.ismobile() ? this.file.tempDirectory + img : img);
            return img;
        }
    }

    readFile(file: any) {
        const reader = new FileReader();
        reader.onloadend = () => {
            const formData = new FormData();
            const imgBlob = new Blob([reader.result], {
                type: file.type
            });
            formData.append('file', imgBlob, file.name);
            this.propertyService.uploadimage(formData).subscribe(
                (image: any) => {
                    //contains image.imgId
                    //this.lastImage = reader.result
                    this.saveImage(image)
                });

        };
        reader.readAsArrayBuffer(file);
    }

    private showLoading(after: any) {
        this.loadingCtrl.create({}).then(ctl => {
            ctl.present();
            this.loading = ctl;
            after();
        });
    }

    /*
    public uploadImage() {

        if (!this.ismobile()) {
            return;
        }


        // Destination URL
        //var url =  this.configService.getSvcURL("property/image");

        // File for Upload
        var targetPath = this.pathForImage(this.image);

        this.file.resolveLocalFilesystemUrl(targetPath)
            .then(entry => {
                (<FileEntry>entry).file(file => this.readFile(file))
            })
            .catch(err => {
                this.presentToast('Error while reading file.');
            });

    }
*/

}