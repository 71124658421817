import {Component, Input} from "@angular/core";
import {ModalController, NavParams} from '@ionic/angular';
import {PropertyService} from "../../services/property.service";
import { ToastController } from '@ionic/angular';
import {FieldLabelConstants} from "../../util/FieldLabelConstants";

@Component({
  selector: 'extended-property-details-modal',
  templateUrl: 'extended-property-details-modal.html'
})
export class ExtendedPropertyDetailsModal {

  private property: any;
  private fieldLabels: any = (new FieldLabelConstants()).fieldLables;
  private fieldLabelKeys: any = Object.keys(this.fieldLabels);

  constructor(public viewCtrl: ModalController, public params: NavParams, public propertyService: PropertyService, public toastCtrl: ToastController) {
    this.property = params.get("property");
  }

  close() {
    this.viewCtrl.dismiss({
      'dismissed': this.property
    });
  }

  save() {
    console.log("saving property: ", this.property);
    this.propertyService.save(this.property).subscribe((property) => {
      console.log("Property saved: ", this.property, property);
      this.property = property;
      this.close();
      this.toastCtrl.create({
        message: 'Extended Property Data Saved Successfully!',
        duration: 3000,
        position: 'top'
      }).then(ctl => {
        ctl.present();
      })
    });
  }

}
