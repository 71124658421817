import {Component, AfterViewInit, HostListener} from '@angular/core';
import {NavController, ActionSheetController} from '@ionic/angular';

import {OrgService} from '../../services/org.service';
import {UserAdminService} from '../../services/user.service';

import _ from 'lodash';

@Component({
  selector: 'page-org-admin',
  templateUrl: 'org-admin.html',
  styleUrls: ['./org-admin.scss']
})
export class OrgAdminPage implements AfterViewInit {
//functions
  public Math = Math;
//gui controls:
  actionSheet: any;

  // trip info
  public orgs: [any];
  public users: [any];
  public selectedorg: any;
  //48vw
  //width: {{orgwidth}}; height: 35vw;
  public orgstyle = "{ 'width': '90vw'; 'height': '35vw'; }"
  //public userwidth = "width: 10vw; height: 35vw;"

  private gridApi;
  private gridColumnApi;



  orgColumnDefs = [
    {headerName: 'Name', field: 'name', editable: true, onCellValueChanged: this.onOrgCellValueChanged() },
    {headerName: 'Domain', field: 'domain', editable: true, onCellValueChanged: this.onOrgCellValueChanged() },
    {headerName: 'Support Name', field: 'supportname', editable: true, suppressSizeToFit: true, onCellValueChanged: this.onOrgCellValueChanged() },
    {headerName: 'Support Email', field: 'supportemail', editable: true, suppressSizeToFit: true, onCellValueChanged: this.onOrgCellValueChanged() },
    {headerName: 'Support Support Phone', field: 'supportphone', editable: true, suppressSizeToFit: true, onCellValueChanged: this.onOrgCellValueChanged() },
    {headerName: 'Default Roles', field: 'defaultroles', editable: true, valueSetter: this.defaultRolesArraySetter, onCellValueChanged: this.onOrgCellValueChanged() },
    {headerName: 'Inactive', 
    field: 'inactive',
    editable: true, 
    cellEditor: 'popupSelect',
    cellRendererSelector: this.bolleanColumnRenderer,
    cellEditorParams: {
        values: [true, false]
       }, 
       onCellValueChanged: this.onOrgCellValueChanged() },
    //colDef.checkboxSelection
    //cellClicked
    {headerName: 'Edit Users', editable: false, checkboxSelection: true },
    //(rowClicked)="onOrgRowClicked($event)"
  ];

  userColumnDefs = [
    {headerName: 'First Name', field: 'firstname', editable: true, onCellValueChanged: this.onUserCellValueChanged() },
    {headerName: 'Last Name', field: 'lastname', editable: true, onCellValueChanged: this.onUserCellValueChanged() },
    {headerName: 'Email', field: 'local.email', editable: true, onCellValueChanged: this.onUserCellValueChanged() },
    {headerName: 'Roles', field: 'roles', editable: true, valueSetter: this.rolesArraySetter, onCellValueChanged: this.onUserCellValueChanged() },
    {headerName: 'Inactive', 
    field: 'inactive',
    editable: true, 
    cellEditor: 'popupSelect',
    cellRendererSelector: this.bolleanColumnRenderer,
    cellEditorParams: {
        values: [true, false]
       }, 
       onCellValueChanged: this.onUserCellValueChanged() },

  ];

  constructor(
    public nav: NavController, 
    public orgService: OrgService, 
    public userService: UserAdminService) {
    
    

  }

  @HostListener('window:resize')
  onResize() {
    if (!this.gridApi) return;
  
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 10);
  }

  ngAfterViewInit() {
      setTimeout(() => {
        if (this.gridApi) {
              this.gridApi.sizeColumnsToFit();
        }
      }, 10);
  }

    save(org){
      this.orgService.save(org).subscribe(
        (org) => {
          this.nav.navigateRoot('home');
      });      
    }

    
    cancel(){
      this.nav.navigateRoot('home');
    }

    add(){
      return this.orgService.defaultOrg();
    }

    onUserCellValueChanged() {
      return (params) =>  {
        params.node.data.org = this.selectedorg._id;
        this.userService.save(params.node.data).subscribe(
          (user: any) => {
            if (user && user._id){
              params.node.data = user;
            }
          }); 
      }
    }

    onOrgCellValueChanged() {
      return (params) =>  {
        this.orgService.save(params.node.data).subscribe(
          (org: any) => {
            if (org && org._id){
              params.node.data = org;
            }
          }); 
      }
    }

    loadUsersforOrg() {
      return (params) =>  {
          params.node.setSelected(!params.node.isSelected())
          this.selectedorg = params.node.data;
          this.orgService.findUsersforOrg(params.node.data._id).subscribe(
            (users: [any]) => {
              this.users = users;
            }); 
     }
    }

    onOrgRowClicked(params){
      if (params.node.selected) {
        this.selectedorg = params.node.data;
        this.orgService.findUsersforOrg(params.node.data._id).subscribe(
          (users: [any]) => {
            this.users = users;
            // let element = <HTMLElement>document.querySelector("#orgs");
            // element.style.width = "40vw";
          }); 
      } else {
        this.selectedorg = undefined;
        this.users = undefined;
        // let element = <HTMLElement>document.querySelector("#orgs");
        // element.style.width = "90vw";
      }
      // this.gridColumnApi.setColumnsVisible(["domain", "supportemail", "defaultroles", "supportname", "supportphone", "inactive"], !params.node.selected);
      //params.node.setSelected(params.node.selected, !params.node.selected);
      return true;
      //
    }

    onOrgGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
  
      this.orgService.findAll().subscribe(
        (orgs: [any]) => {
          this.orgs = orgs;
        }); 

    }

    onUserGridReady(params) {
      setTimeout(() => {
          params.api.sizeColumnsToFit();
      }, 10);

    }


    defaultRolesArraySetter(params) {
      try {
        let roles = params.newValue.split(',');
        params.data.defaultroles = roles;
        return true;  
      } catch (e){
        return false
      }
    }

    rolesArraySetter(params) {
      try {
        let roles = params.newValue.split(',');
        params.data.roles = roles;
        return true;  
      } catch (e){
        return false
      }
    }

    onGridSizeChanged(params){
      //this.gridApi.sizeColumnsToFit();
      params.api.sizeColumnsToFit();
    }

    bolleanColumnRenderer(params) {
      //return (params.value && params.value === 'true' ? '<i>&#9745;</i>' : '<i>&#9746;</i>');
      return (!params.value ? '<b>Inactive</b>' : '<b>Active</b>');
  }
}
