import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './config.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Events } from '@ionic/angular';


@Injectable()
export class AppService {

  private readonly configService: ConfigService = new ConfigService();
  private config = this.configService.getConfig();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly events: Events
  ) {
  }

public saveSvcURL(server:string){
    localStorage.setItem('serviceurl', server);
}

public getSvcURL(){
    try {
        return localStorage.getItem('serviceurl');
    } catch (e){
        return this.config.server;
    }
}

public clearSvcURL(){
    try {
        localStorage.removeItem('serviceurl');
    } catch (e){
        //nothing to do here.
    }
}

public needsSvcURL() {
    return this.config.lookupserver;
}

public getDetaulSvcUrl(){
    return this.config.server;
}

public listSvcURL(username){
    if (this.config.lookupserver){
        return this.httpClient.get(this.config.server + 'appservice?username=' + username)
        .catch((e: any) => this.catchErrors(e, this.events));
    }
    return;
}

private catchErrors(err: any, events: Events) {
    //Just fall back to the config entry....
    this.saveSvcURL(this.config.server);
    events.publish('service.exception', {message: 'Unable to retrieve associated service URL, using default. This may result in unexptected behaviour'});
    return Observable.throw(err);
}

}
