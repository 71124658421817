export let ModifierTypes = [
    {
        id: 2,
        name: 'Interior',
        custom: false
    },
    {
        id: 1,
        name: 'Exterior',
        custom: false
    },
    {
        id: 3,
        name: 'Optional',
        custom: false
    }, 
    {
        id: 4,
        name: 'Custom',
        custom: true
    }
]

export let Modifiers = [
    { //done
        id: 1,
        name: "Upgraded Kitchen",
        range: [
                "Cabinets in disrepair",
                "Original no upgrades fair condition",
                "Original no upgrades excellent condition",
                "Remodeled Kitchen / Granite / SS appliances",
                "Fully upgraded kitchen",
        ],
        start:-5,
        end: 5,
        modtypeid: 2
      },
      {
        id: 2,
        name: "Upgraded Master",
        range: [
                "Original no upgrades poor condition",
                "Original no upgrades fair condition",
                "Original no upgrades excellent condition",
                "Remodeled Master / Master Bath",
                "Fully Upgraded Master Suite",
        ],
        start:-5,
        end: 5,
        modtypeid: 2
      },
      {
        id: 3,
        name: "Open floor plan",
        range: [
            "No open floor plan",
            "Limited open floor plan",
            "Open Living Area",
            "Open First Floor",
            "Full Open Floor Plan",
        ],
        start:-1,
        end: 1,
        modtypeid: 2
      },
      {
        id: 4,
        name: "Upgraded floor and wall finishes",
        range: [
                "Original no upgrades poor condition",
                "Original no upgrades fair condition",
                "Original no upgrades excellent condition",
                "Hard wood floors / New paint",
                "Fully Upgraded floors and paint",
        ],
        start:-3,
        end: 3,
        modtypeid: 2
      },
      {
        id: 5,
        name: "Landscaping",
        range: [
                "Landscape in poor condition",
                "Landscape in fair condition",
                "Landscape in good condition",
                "Landscape in excellent condition",
                "Fully Upgraded landscape",
        ],
        start:-3,
        end: 3,
        modtypeid: 1
      },
      {
        id: 6,
        name: "Yard Features",
        range: [
                "Pool or water feature in poor condition",
                "Hardscape / built in BBQ in poor condition",
                "No yard features",
                "Hardscape / built in BBQ in good shape",
                "In ground Pool / Spa in good shape",
        ],
        start:-5,
        end: 5,
        modtypeid: 1
      },
      {
        id: 7,
        name: "Privacy / Noise",
        range: [
                "Homes on all sides & or busy street",
                "Homes on 2 sides street not busy",
                "Quiet street",
                "Cul-de-sac",
                "Seperation from most homes",
                "Seperation from all homes",
                "Very private",
        ],
        start:-1,
        end: 1,
        modtypeid: 1
      },
      {
        id: 8,
        name: "View",
        range: [

                "No View",
                "Limited View",
                "Good view",
                "Great view",
                "Great view to include ocean, lake, or river",
        ],
        start:-1,
        end: 1,
        modtypeid: 1
      },
//Beach front or lake front or river front.
{
    id: 11,
    name: "Water Front",
    range: [
            "Not water front",
            "Withing 1 mile of water",
            "Within 1 block of water",
            "River front",
            "Lake front",
            "Ocean front",
    ],
    start: 0,
    end: 5,
    modtypeid: 3
},
//On a certain side of a major dividing artery such as Interstate 5.
{
    id: 12,
    name: "Interstate Proximity",
    range: [
            "More than 15 from an inerstate",
            "More than 10 from an inerstate",
            "Within 1 mile of inerstate",
            "Within 5 mile of inerstate",
            "Within 10 mile of inerstate",
    ],
    start: -3,
    end: 1,
    modtypeid: 3
},
//Gated or top tier neighborhood.  (Universally recognized as number 1 or 2 or 3 most desirable neighborhood.
{
    id: 12,
    name: "Gated or desirable neighborhood",
    range: [
            "Not gated",
            "Gated/not top tier",
            "#3 most desirable",
            "#2 most desirable",
            "#1 most desirable"
    ],
    start: 0,
    end: 5,
    modtypeid: 3
},
//Garage
{
    id: 14,
    name: "Garage and Parking",
    range: [
            "No Garage/parking",
            "Uncovered parking",
            "Covered parking",
            "Shared Garage",
            "Single Car Garage",
            "Double Car Garage",
            "Multiple Car Garage"
    ],
    start: 0,
    end: 5,
    modtypeid: 3
},
//Swimming pool
{
    id: 18,
    name: "Swimming Pool/Spa",
    range: [
            "No swimming pool/spa",
            "Aged swimming pool/spa",
            "Well maintiained pool/spa",
            "Fully updraded pool/spa",
    ],
    start: 0,
    end: 5,
    modtypeid: 3
},
//Concierge (for Condo sale)
{
    id: 20,
    name: "Special Services (typically for Condos)",
    range: [
            "No Concierge",
            "Concierge with extra costs",
            "Concierge with limited hours",
            "24hr Concierge",
    ],
    start: 0,
    end: 5,
    modtypeid: 3
},
//Metro Proximity
{
    id: 21,
    name: "Metro Proximity",
    range: [
            "20+ miles from major metro",
            "10+ miles from major metro",
            "5+ miles from major metro",
            "Within major metro area",
    ],
    start: 0,
    end: 5,
    modtypeid: 3
},
//All New Windows
{
    id: 23,
    name: "Windows",
    range: [
            "No new windows",
            "Some new windows",
            "New windows front elevation",
            "All new windows"
    ],
    start: 0,
    end: 5,
    modtypeid: 3
}
]
