import {Injectable} from "@angular/core";
import { ObjectId } from '@tybys/oid';

export class CustomModifier {
      _id: ObjectId;
      title: string;
      description: string;
      range: Array<string>;
      s1v: number;
      s2v: number;
      s3v: number;
      s4v: number = 0;
      s5v: number;
      s6v: number;
      s7v: number;
      type: String = 'Custom';
      show: boolean;
      subdesc: string = 'Average';
      //rangepoints are used to initilize the range array later.
      rangepoints: number;

      constructor(init?) {
        if (init){
          this.title = init.title;
          this.description = init.description;
          this._id = (init._id == null ? new ObjectId(): new ObjectId(init.id));
          this.s1v = parseInt(init.s1v);
          this.s2v = Math.ceil (this.s1v - (this.s1v/3));
          this.s3v = Math.ceil(this.s2v - (this.s1v/3));
          this.s7v = parseInt(init.s7v);
          this.s6v = Math.floor(this.s7v - (this.s7v/3));
          this.s5v = Math.floor(this.s6v - (this.s7v/3));
          this.subdesc = (init.subdesc == null ? this.subdesc : init.subdesc);
          if (init.rangepoints && parseInt(init.rangepoints) !== init.range.length){
            this.range = new Array(parseInt(init.rangepoints));
            //this.range.fill("Set a custom description");
          } else {
            this.range = init.range;
          }
        } else {
          this._id = new ObjectId();
          this.range = [];
        }
        //This is for custom, so we start with blank, later we start with a service call
        
      }
}

@Injectable()
export class CustomModifierService {
  private modifiers: any;

  constructor() {
    //This is for custom, so we start with blank, later we start with a service call
    this.modifiers = [];
  }

  getAll() {
    return this.modifiers;
  }

  getItem(id) {
    for (var i = 0; i < this.modifiers.length; i++) {
      if (this.modifiers[i]._id === parseInt(id)) {
        return this.modifiers[i];
      }
    }
    return null;
  }

  remove(item) {
    this.modifiers.splice(this.modifiers.indexOf(item), 1);
  }

  add(modifier: CustomModifier, callback){
    modifier = new CustomModifier(modifier);
    modifier._id = new ObjectId();
    if (modifier.rangepoints && modifier.range.length !== modifier.rangepoints){ 
        modifier.range = new Array(modifier.rangepoints);
        modifier.range.fill("Add custom description.");
    }    
    this.modifiers.push(modifier);
    if (callback){
      return callback(modifier);
    } 
    return modifier;
  }

  create(){
    return new CustomModifier;
  }
}
