import {Component, Input} from "@angular/core";
import {ModalController, NavParams} from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'video-modal',
  templateUrl: 'video-modal.html',
  styleUrls: ['./video-modal.scss']
})
export class VideoModal {

    welcomeVideo: string = "";

    constructor(public viewCtrl: ModalController, public params: NavParams, private sanitizer: DomSanitizer) {
        this.welcomeVideo = this.params.data['welcomeVideo'];
    }

    close() {
        document.cookie = "video=true;expires=Thu, 18 Dec 2099 12:00:00 UTC;";
        //window.localStorage.setItem('video', 'true');
        this.viewCtrl.dismiss({});
    }

    trustedVideo() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.welcomeVideo);
      }

}