import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';

export interface ISearchParam {
    readonly display: any;
    readonly key: string;
    readonly type: string;
    value: string;
  }

  export interface ISearchParams {
    [id: string] : ISearchParam
  }

@Injectable()
export class SourcedataService {

    public location: any;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigService) {


    };


    getParamFields(): Observable<ISearchParams> {
        return this.httpClient.get<ISearchParams>('sourcedata/searchfields');
    };

    getProperties(params:any): Observable<any> {
        return this.httpClient.get<any>('sourcedata' + this.paramsQuery(params) + "&limit=100");
    };

    paramsQuery(params:any): string{
        let ret = ""
        let paramkeys = Object.keys(params)
        paramkeys.forEach((key) => {
            if (params[key].value){
                ret = ret + key + '=' + params[key].value + '&';
            }
        });
        return (ret.length > 0 ? '?' + ret : ret);
    }

}
