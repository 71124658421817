import { Component, NgModule } from "@angular/core";
import {ModalController, NavController, ToastController} from '@ionic/angular';
import {NavParams} from '@ionic/angular';


@Component({
  selector: 'modifier-import-modal',
  templateUrl: 'modifier-import-modal.html',
  styleUrls: ['./modifier-import-modal.scss'],

})
export class ModifierImportAdminPage {


}