import { Component, NgModule } from "@angular/core";
import {ToastController, ModalController} from '@ionic/angular';
import {ModifierService} from "../../../services/modifier-service";

@Component({
  selector: 'modifier-add-modal',
  templateUrl: 'modifier-add-modal.html',
  styleUrls: ['./modifier-add-modal.scss'],
})



export class ModifierAddModal {

  private modifier: any = {};

  constructor(public viewCtrl: ModalController, public toastCtrl: ToastController, public modifierService: ModifierService) {
    this.modifier = {};
  }

  close() {
    this.viewCtrl.dismiss({
      'dismissed': true
    });
  }

  save() {
    console.log("sending save for: ", this.modifier);
    this.modifierService.save(this.modifier).subscribe((data) => {
      console.log("got info back: ", data);
      this.close();
      this.toastCtrl.create({
        message: 'Feature VALUmodifier has been added Successfully!',
        duration: 3000,
        position: 'top'
      }).then(ctl => {
        ctl.present();
      })
    });
  }

}
